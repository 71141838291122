<template>
    <UHeader :links="links" :to="rootPath">
        <template #logo>
            <p class="tracking-tight">{{ headerTitle }}</p>
        </template>
        <template #center>
            <div></div>
        </template>
        <template #right>
            <UDropdown v-if="isAuthenticated && !$device.isMobile" :items="accountItems">
                <UButton color="gray" variant="ghost" square icon="i-heroicons-ellipsis-horizontal" />
            </UDropdown>
        </template>
        <template #panel>
            <UHeaderPopoverLinks :links="links" />
            <UDivider class="my-4" />
            <div class="mt-4 space-y-2">
                <!-- <UColorModeButton block label="Color mode" variant="soft" color="white" /> -->
                <UButton v-if="!isAuthenticated" label="Sign in" block size="xl" color="primary" to="/login"
                    variant="soft" />
                <UButton label="Sign out" icon="i-heroicons-arrow-left-end-on-rectangle" @click="signOutUser" block
                    variant="soft" v-if="isAuthenticated" />
            </div>
        </template>
    </UHeader>
</template>
<script setup lang="ts">

const { signOutUser } = useFirebaseAuth();

const route = useRoute();
console.log('route:', route)
const authStore = useAuthStore();
const { isAuthenticated } = storeToRefs(authStore);

const onboardStore = useOnboardStore();
const { partner } = storeToRefs(onboardStore);

const headerTitle = computed(() => {
    return partner.value ? partner.value.short_name : 'NomadRx'
})
const rootPath = computed(() => {
    return partner.value ? `/onboard/${partner.value.slug}` : '/onboard'
})


const accountItems = [
    [{
        label: 'Go to homepage',
        icon: 'i-heroicons-home',
        to: "/",
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            await signOutUser();
        }
    }]
]


const links = [{
    label: 'Homepage',
    to: '/',
    icon: 'i-heroicons-home',
    description: 'Go to the home page'
}, {
    label: 'Onboarding',
    to: rootPath.value,
    exact: true,
    icon: 'i-heroicons-check-badge',
    description: 'Create your NomadRx account'
}]
</script>